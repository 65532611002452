<mat-expansion-panel
  class="ref-panel"
  [ngStyle]="{'pointer-events': ref.referrals && ref.referrals.length > 0 ? 'all' : 'none' }"
  [hideToggle]="!ref.referrals || ref.referrals.length === 0">

  <mat-expansion-panel-header>
    <div class="container-fluid">
      <div class="row align-items-center">
         <div class="col-md-5" *ngIf="ref.address">
          <div class="caption text-muted mb-1">Wallet Address</div>
          <span class="d-flex align-items-center flex-nowrap" title="{{ref.address}}">
            <img [src]="2 | imgTierPipe" height="24" />
            <span class="overflow-ellipsis ms-2">{{ref?.address}}</span>
          </span>
        </div>

        <div class="col-md-3">
          <div class="caption text-muted mb-1">Users Referred</div>
          <span
            class="overflow-ellipsis"
            title="{{ !!ref.referrals ? ref.referrals.length : 0 }}">
            {{ !!ref.referrals ? ref.referrals.length : 0 }}
          </span>
        </div>

        <div class="col-md-2" *ngIf="ref.stackedAmount || ref.stackedAmountSumm">
          <div class="caption text-muted mb-1">
            BLP Staked
          </div>
          <span
            class="d-flex align-items-center"
            title="{{ref.stackedAmount | shiftDecimals: -18 | number: '1.0-2'}}">
            <img src="../../assets/images/tokens/blp.svg" alt="" class="image-24">
            <span class="overflow-ellipsis ms-1" *ngIf="ref.stackedAmount">{{ref.stackedAmount | shiftDecimals: -18 | number: '1.0-2'}}</span>
            <span class="overflow-ellipsis ms-1" *ngIf="ref.stackedAmountSumm">{{ref.stackedAmountSumm.toString() | shiftDecimals: -18 | number : '1.0-2'}}</span>
          </span>
        </div>

        <div class="col-md-2" *ngIf="ref.rewardAmount || ref.rewardAmountSumm">
          <div class="caption text-muted mb-1">BLP Earned</div>
          <span
            class="d-flex align-items-center"
            title="{{ref.rewardAmount | shiftDecimals: -18 | number: '1.0-2'}}" >
            <img src="../../assets/images/tokens/blp.svg" alt="" class="image-24">
            <span class="overflow-ellipsis ms-1" *ngIf="ref.rewardAmount">{{ref.rewardAmount | shiftDecimals: -18 | number: '1.0-2'}}</span>
            <span class="overflow-ellipsis ms-1" *ngIf="ref.rewardAmountSumm">{{ref.rewardAmountSumm.toString() | shiftDecimals: -18 | number : '1.0-2'}}</span>
          </span>
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <!-- nested expantion panels if exist -->
  <ng-container *ngIf="ref && ref.referrals">
    <app-ref-extantion-panel-inner *ngFor="let item of ref.referrals | filter : 'address' : searchString" [ref]="item"></app-ref-extantion-panel-inner>
  </ng-container>
</mat-expansion-panel>

