import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  StakingPoolDTO,
  StakingPoolDurationUpdateDTO,
  StakingPoolServiceProxy,
  StakingPoolUpdateDTO,
  StakingPoolWhitelistUpdateDTO
} from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { EventBus } from 'src/shared/event-bus';
import { NetworkNamePipe } from 'src/shared/pipes/networkName.pipe';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { Web3Service } from 'src/shared/web3-service';
import { BigNumber } from 'bignumber.js';

@Component({
  templateUrl: './edit-pool.component.html',
  styleUrls: ['./edit-pool.component.scss']
})
export class EditPoolComponent extends ComponentBase implements OnInit {

  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private stakingPoolService: StakingPoolServiceProxy,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.originStringWhitelist = "";
   }

  waiting: boolean;
  item: StakingPoolDTO;
  poolAddress: string;
  account: string;

  poolName: string;
  poolDescription: string;
  startTime: number;
  finishTime: number;
  hasWhiteListing: boolean;
  poolTokenAmount: string;
  depositFeeBP: number;
  feeTo: string;
  visibleForUsers: boolean;
  addToTierCount: boolean;
  isApproved: boolean;
  isPublic: boolean;
  indexInCalculator: number;
  stringWhitelist: string;
  originStringWhitelist: string;

  feeAmount: number;
  feeToken: string;

  stakingToken: string;
  stakingTokenDecimals: number;
  poolToken: string;

  extending: boolean = false;
  reducing: boolean = false;

  extendTokenAmount: number = 0;
  extendDuration: number;
  reduceTime: number;

  allPenalties: Array<any>;
  waitingPenalty: boolean;
  updatePenaltyIndex: number;
  updatePenaltyPeriod: number;
  updatePenaltyPercent: number;

  public get isInCalculator(): boolean {
    return this.indexInCalculator != -1;
  }

  async ngOnInit(): Promise<void> {
    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.poolAddress = params['address'];
        console.log(`staking pool address: ${this.poolAddress}`);
        this.getStakingPoolDTO();
        this.getFeeAmount();
        this.getCalculatorStatus();
      });

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    //this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    //});

    //this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    //});
    this.account = this.userSessionProvider.linkedWallet;
  }

  async getStakingPoolDTO(): Promise<void> {
    this.allPenalties = await this.web3Service.getPenalties(this.poolAddress);
    this.stakingPoolService.getByAddress(this.poolAddress).subscribe(result => {
      this.item = result;
      this.depositFeeBP = result.depositFeeBP;
      this.startTime = result.startTime;
      this.finishTime = result.finishTime;
      this.hasWhiteListing = result.hasWhitelisting;
      this.stakingToken = result.stakingToken;
      this.stakingTokenDecimals = result.stakingTokenDecimals;
      this.poolToken = result.poolToken
      this.poolTokenAmount = (parseInt(result.poolTokenAmount) / (10 ** 18)).toString();
      this.poolName = result.name;
      this.poolDescription = result.description;
      this.visibleForUsers = result.visibleForUsers;
      this.isApproved = result.isApproved;
      this.isPublic = result.isPublic;
      this.stringWhitelist = result.whitelistingAddresses;
      this.originStringWhitelist = result.whitelistingAddresses;

      console.log(this.item);
      // if (this.web3Service.chainIdNumber != this.item.chainId) {
      //   let chainName = new NetworkNamePipe().transform(this.item.chainId);
      //   this.showErrorModal(`Select ${chainName} network in metamask!`);
      //   this.router.navigate(["/staking"]);
      // }
    },
      error => {
        this.processServiceError(error);
      });
  }

  select(index: number) {
    this.updatePenaltyIndex = index;
    this.updatePenaltyPeriod = this.allPenalties[index].duration / 86400;
    this.updatePenaltyPercent = this.allPenalties[index].penaltyBP / 100;
  }

  async updatePenaltyClick(): Promise<void> {
    if (this.updatePenaltyPeriod > 0 && this.updatePenaltyPercent > 0) {
      this.waitingPenalty = true;
      try {
        await this.web3Service.updateStakingPoolPenalty(this.account, this.poolAddress, this.updatePenaltyIndex, this.updatePenaltyPeriod * 86400, this.updatePenaltyPercent * 100);
        this.getStakingPoolDTO();
        this.showInfoModal("Saved");
      }
      catch(error) {
        this.processServiceError(error);
      }
      finally {
        this.waitingPenalty = false;
      }
    } else {
      return;
    }
  }

  async updateStakingPoolDb(): Promise<void> {
    this.waiting = true;

    var data: StakingPoolUpdateDTO = new StakingPoolUpdateDTO();
    data.poolAddress = this.poolAddress;
    data.name = this.poolName;
    data.description = this.poolDescription;
    data.visibleForUsers = this.visibleForUsers;
    data.isApproved = this.isApproved;
    data.isPublic = this.isPublic;

    console.log(data);

    this.waiting = true;

    try {
      if (this.addToTierCount != this.isInCalculator) {
        if (this.addToTierCount) {
          await this.web3Service.addPoolToCalculator(this.account, this.item.poolAddress).receipt$.toPromise();
        }
        else {
          await this.web3Service.removePoolFromCalculator(this.account, this.indexInCalculator).receipt$.toPromise();
        }
      }

      await this.stakingPoolService.update(data).toPromise();

      this.getStakingPoolDTO();
      this.getCalculatorStatus();

      this.showInfoModal("Saved");
    }
    catch(error) {
      this.processServiceError(error);
    }
    finally {
      this.waiting = false;
    }
  }

  async updateWhitelistClick(): Promise<void> {
    this.waiting = true;
    // interact with contract:
    this.updateWhitelistContract();
    var data: StakingPoolWhitelistUpdateDTO = new StakingPoolWhitelistUpdateDTO();
    data.poolAddress = this.poolAddress;
    data.hasWhitelisting = this.hasWhiteListing;
    data.addresses = this.stringWhitelist;

    console.log(data);

    this.waiting = true;

    this.stakingPoolService.updateWhiteList(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.getStakingPoolDTO();
          this.showInfoModal("Saved")
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateWhitelistContract() {
    let oldAddresses = this.originStringWhitelist.split(',');
    let newAddresses = this.stringWhitelist.trim().split(',');
    let addressesToAdd = newAddresses.filter(p => !(oldAddresses.includes(p)));
    let addressesToRemove = oldAddresses.filter(p => !newAddresses.includes(p));
    console.log("addressesToAdd");
    console.log(addressesToAdd);
    console.log("addressesToRemove");
    console.log(addressesToRemove);
    if(addressesToAdd.length > 0 && addressesToAdd[0] !="") {
      const contractEventsSource = this.web3Service.addAddressesToWhitelist(this.account, this.item.poolAddress, addressesToAdd);

      try {
        let response = await contractEventsSource.receipt$.toPromise();
        console.log(response);
        this.showInfoModal('Confirmed transaction');
      } catch (err) {
        console.info('catch');
        console.info(err);
      }
    }
    if(addressesToRemove.length > 0 && addressesToRemove[0] !="") {
      const contractEventsSource = this.web3Service.removeAddressesToWhitelist(this.account, this.item.poolAddress, addressesToRemove);

      try {
        let response = await contractEventsSource.receipt$.toPromise();
        console.log(response);
        this.showInfoModal('Confirmed transaction');
      } catch (err) {
        console.info('catch');
        console.info(err);
      }
    }
    // console.log(oldAddresses);
    // console.log(newAddresses);

  }

  async extendPoolDuration() {
    const duration = this.extendDuration || 0;
    const amount = this.extendTokenAmount || 0

    if (amount <= 0) {
      this.showErrorModal('Invalid token amount');
      return;
    }

    try {
      this.waiting = true;

      const tokenAmount = new BigNumber(this.extendTokenAmount).shiftedBy(this.stakingTokenDecimals).toString(10);
      await this.web3Service.ensureAllowance(this.account, this.poolAddress, this.stakingToken, tokenAmount);

      const contractEventsSource = this.web3Service.extendDuration(this.account, this.poolAddress, tokenAmount, duration);
      await contractEventsSource.receipt$.toPromise();

      const [finishTime, poolTokenAmount] = await Promise.all([
        this.web3Service.getFinishTime(this.poolAddress),
        this.web3Service.getPoolTokenAmount(this.poolAddress)
      ]);

      const data: StakingPoolDurationUpdateDTO = new StakingPoolDurationUpdateDTO();
      data.poolAddress = this.poolAddress;
      data.finishTime = Number(finishTime);
      data.poolTokenAmount = poolTokenAmount;

      await this.stakingPoolService.updateDuration(data).toPromise();
      await this.getStakingPoolDTO();

      this.showSuccessModal('Stacking Pool successfully extended');
    }
    catch (err) {
      this.processServiceError(err);
    }
    finally {
      this.waiting = false;
      this.extending = false;
    }
  }

  async reducePoolDuration() {
    const timestamp = this.reduceTime || 0

    if (timestamp <= 0) {
      this.showErrorModal('Invalid finish time');
      return;
    }

    try {
      this.waiting = true;

      const contractEventsSource = this.web3Service.reduceDuration(this.account, this.poolAddress, timestamp);
      await contractEventsSource.receipt$.toPromise();

      const [finishTime, poolTokenAmount] = await Promise.all([
        this.web3Service.getFinishTime(this.poolAddress),
        this.web3Service.getPoolTokenAmount(this.poolAddress)
      ]);

      const data: StakingPoolDurationUpdateDTO = new StakingPoolDurationUpdateDTO();
      data.poolAddress = this.poolAddress;
      data.finishTime = Number(finishTime);
      data.poolTokenAmount = poolTokenAmount;

      await this.stakingPoolService.updateDuration(data).toPromise();
      await this.getStakingPoolDTO();

      this.showSuccessModal('Stacking Pool duration successfully reduced');
    }
    catch (err) {
      this.processServiceError(err);
    }
    finally {
      this.waiting = false;
      this.reducing = false;
    }
  }

  onApprovedChanged(approved: boolean): void {
    this.isApproved = approved;
    this.isPublic = !approved;
  }

  async getFeeAmount(): Promise<void> {
    this.feeAmount = (await this.web3Service.GetStakeMasterFeeAmount()) / (10 ** 18);
    console.log(this.feeAmount);
  }

  async getCalculatorStatus() {
    this.indexInCalculator = await this.web3Service.getPoolIndex(this.poolAddress);
    this.addToTierCount = this.isInCalculator;
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = "";
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
