export const environment = {
  production: false,
  polygon: {
    merkleDistributorCreatorAddress: '0x421d618389d63c0DfDB43Dff1cAD448E52708C9D'
  },
  eth: {
    merkleDistributorCreatorAddress: '0x421d618389d63c0DfDB43Dff1cAD448E52708C9D'
  },
  bsc: {
    blpAddress: '0xE652AF898ECB3a4666EedBd2415B2c2De300BF52',
    dealCreatorAddress: '0x7dA548122a42b0bE18f2de4497676AC84cFc1CD6',
    dealCollectWalletCreatorAddress: '0xBc4EDfe0615A5FaF68485BE8e055aF1290A2caA2',
    lockerAddress: '0x6F8dF389c8a647a572F563d5c5Fd2A5EF259beB2',
    dealLockupsAddress: '0xfe8E809532B2FFD62Cb093F988a12859dF8A3238',
    stakingPoolMasterAddress: '0x8e6f3F352A961E4E079573EF985823D3cF5bc447',
    merkleDistributorCreatorAddress: '0x8A8d165Be94991a3B28F20E8bE1eFDc8646De318',
    pancakeRouterAddress: '0x354bc1cFe9959b96beD409859a726263C6C56C81',
    tierCalculatorAddress: '0xFA4e99034B73A70E5Bbc6c31eD05F7752d1B2239',
    stackingAddress: '0xf2568244e4580f790387c18adc0fa5c14b15b91f',
    bonusAddress: '0x53bd060227c4499c2c3d1575b2519b5b9e9bade2',
    paymentTokens: [
      { name: "BNB", address: "0x0000000000000000000000000000000000000000", icon: 'bnb.svg'},
      { name: "BUSD", address: "0xDaaa52B192743f57f8dC4A26F9287ADCdaAA7ba3", icon: 'busd.svg'},
      { name: "USDT", address: "0x4f2CbA5e9B6C6514E2F1F4c6Be3db66Dbe4E164b", icon: 'usdt.svg'},
      { name: "USDC", address: "0xC033A42791d035cDFC7634c624adB9b8eB5906e1", icon: 'usdc.svg'}
    ],
    networkTypes: [
      { id: 1, label: 'ETH' },
      { id: 56, label: 'BSC' },
      { id: 137, label: 'Polygon' },
      { id: -100, label: 'Solana' },
      { id: -200, label: 'Tezos'},
      { id: 97, label: 'BSC Testnet' },
      { id: 42, label: 'Kovan' },
      { id: 80001, label: 'Polygon Testnet' },
      { id: 43114, label: 'Avalanche'},
      { id: 250, label: 'Fantom' },
      { id: 321, label: 'KCC' },
      { id: 322, label: 'KCC test'},
      { id: -9999100, label: 'Elrond' },
      { id: -9999101, label: 'Cardano' },
      { id: -9999102, label: 'Other' },
      { id: -9999103, label: 'Polkadot' },
      { id: -9999104, label: 'Cosmos' },
      { id: -9999105, label: 'Flow' },
      { id: -9999106, label: 'Near' },
      { id: 344435, label: 'Skale'},
      { id: 888, label: 'Wanchain'},
      { id: 1666600000, label: 'Harmony'},
      { id: 66, label: 'OKC'},
      { id: 42161, label: 'Arbitrum'},
      { id: -9999107, label: 'Sui'},
      { id: -9999108, label: 'Bitcoin'},
      { id: -9999109, label: 'Aptos'},
      { id: 10, label: 'Optimism'},
      { id: 8453, label: 'Base'},
      { id: 1284, label: 'Moonbeam'},
      { id: 592, label: 'Astar'},
      { id: 1890, label: 'Lightlink'},
      { id: -9999110, label: 'TON'},
      { id: 81457, label: 'Blast'},
      { id: -9999111, label: 'Injective'},
    ]
  },
  remoteServiceBaseUrl: "https://blp-adminapi-test.bullperks.com",
  authServiceBaseUrl: "https://bullauth.gpkit.link",
 
 
  applicantUrl: 'https://kycaid.com/en/applicants/',
  defaultChainId: 97,
  supportedChains: [97, 56, 1, 42],
};
