<header class="mob-menu-header bg-dark">
  <button
    class="mob-menu-toggle"
    type="button"
    (click)="toggleMenu()"
  >
    <b></b>
  </button>

  <div class="mob-menu-header__title" [ngSwitch]="routerPath">
    <span *ngSwitchCase="'/login'">Login</span>
    <span *ngSwitchCase="'/users'">Users</span>
    <span *ngSwitchCase="'/user-details'">User Details</span>
    <span *ngSwitchCase="'/admins'">Admins</span>
    <span *ngSwitchCase="'/penalties'">Penalties</span>
    <span *ngSwitchCase="'/tiers'">Tiers</span>
    <span *ngSwitchCase="'/settings'">Settings</span>

    <span *ngSwitchCase="'/deals'">Deals</span>
    <span *ngSwitchCase="'/create-wallet-deal'">Create Deal</span>
    <span *ngSwitchCase="'/edit-deal'">Edit Deal</span>
    <span *ngSwitchCase="'/deal-detail'">Deal Details</span>

    <span *ngSwitchCase="'/staking'">Staking pool</span>
    <span *ngSwitchCase="'/farming'">Farming pool</span>
    <span *ngSwitchCase="'/pool-detail'">Pool Details</span>
    <span *ngSwitchCase="'/edit-pool'">Edit Pool</span>
    <span *ngSwitchCase="'/create-pool'">Create Pool</span>
  </div>

  <div class="mobile-logo">
    <a href="/"
      ><img src="../../assets/images/logo-bull.svg" alt="Bull Starter"
    /></a>
  </div>
</header>
