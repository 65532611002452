import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';
import { BigNumber } from "bignumber.js";
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DealDTO, DealServiceProxy } from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';

@Component({
  selector: 'deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss'],
})

export class DealsComponent extends ComponentBase implements OnInit {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
    private router: Router
  ) {
    super();
  }


  public tabType: string = "verified";
  public upcomingDeals: Array<DealDTO> = [];
  public inProgressDeals: Array<DealDTO> = [];
  public endedDeals: Array<DealDTO> = [];
  public allDealsFiltered: Array<DealDTO> = [];
  public allDeals: Array<DealDTO> = [];
  public nowTimeStamp: number = Math.floor(Date.now() / 1000);
  public dealTypeFilter = 0;


  waiting: boolean = false;
  account: string = '';
  waitingDeals: boolean = true;

  async ngOnInit() {
    await this.web3Service.initWeb3();
    //fix active tab bug n load
    window.dispatchEvent(new Event('resize'));
    //this.eventBus.loginEvent.subscribe(result => {
    //  console.log('loginEvent subscription:' + result);
    //  this.eventLogin(result);
    //});

    //this.eventBus.logoutEvent.subscribe(result => {
    //  console.log('logoutEvent subscription:' + result);
    //  this.eventLogout();
    //});

    //this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    //});

    //this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    //});

    this.dealService.getForChain(this.web3Service.chainIdNumber)
      .subscribe(result => {
        this.allDeals = result;
        this.waitingDeals = false;
        this.filterDeals();
      },
        error => {
          this.processServiceError(error);
        });
  }

  public selectType(type:number){
    this.dealTypeFilter = type;
    this.filterDeals();
  }

  filterDeals() {
    this.allDealsFiltered = this.allDeals.filter(p => p.dealType == this.dealTypeFilter);
    this.upcomingDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp < p.startTime);
    this.inProgressDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp >= p.startTime && this.nowTimeStamp <= p.finishTime);
    this.endedDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp > p.finishTime);

    console.log(this.allDeals.filter(d =>d.marketPrice))
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
