import {Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {ComponentBase} from '../../shared/component-base';
import {UserDTO, UsersServiceProxy} from '../../service-proxies/service-proxies';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Sort, SortDirection} from '@angular/material/sort';
import { Web3Service } from 'src/shared/web3-service';
import { BigNumber } from "bignumber.js";
import { DlgSnapshotComponent } from '../dlg-snapshot';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends ComponentBase implements OnInit {
  public users: Array<UserDTO> = [];
  waiting: boolean = false;
  account: string = '';
  public totalCount: number | null = null;
  private _pageSize: number = 25;
  public page: number = 0;
  public sortByColumn: string = '';
  public sortDirection: SortDirection = '';
  loading: boolean = false;
  snapshotLoading: boolean = false;
  lastSearch: string = null;
  tierIndex: number = null;
  tiers: number[] = [0, 1, 2, 3, 4, 5];
  bonusAmounts: string;

  snapshotStatus: any;
  timerId: NodeJS.Timeout;

  public get snapshotDate(): string {
    if (this.snapshotStatus?.endDate) {
      return new Date(this.snapshotStatus.endDate).toLocaleString();
    }
    else {
      return '';
    } 
  }

  public get pageSize(): number {
    const savedSize = localStorage.getItem('users_pageSize');
    if (savedSize){
      this._pageSize = +savedSize;
    }
    return this._pageSize;
  }

  public set pageSize(value){
    this._pageSize = value;
    localStorage.setItem('users_pageSize', value.toString());
  }

  public async saveData(): Promise<void> {
    const allData = await this.usersServiceProxy.searchUsers(this.searchString, null, null, null, null, this.tierIndex === -1 ? null : this.tierIndex).toPromise();
    const csvString = this.convertObjectToCSV(allData.items);
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'user_search.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
    return csv;
  }

  constructor(
    private userSessionProvider: UserSessionProvider,
    private usersServiceProxy: UsersServiceProxy,
    private router: Router,
    private web3Service: Web3Service,
    private _dialog: MatDialog,
  ) {
    super();
  }
 
  searchString: string;
  public getApplicantURL(applicantId: string): string {
    return `${environment.applicantUrl}${applicantId}`;
  }

  async ngOnInit() {
    this.account = this.userSessionProvider.linkedWallet;
    this.loading = true;
    this.usersServiceProxy.searchUsers(null, this.pageSize, this.page, this.sortDirection, this.sortByColumn)
      .subscribe(result => {
        this.totalCount = result.totalItems;
        this.users = result.items;
      },
        error => {
          this.processServiceError(error);
        }, () => {
          this.loading = false;
        });

    await this.initSnapshotStatus();
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this.showErrorModal(error.message || JSON.parse(error.response).message);
  }

  async initSnapshotStatus() {
    this.snapshotStatus = await this.usersServiceProxy.getSnapshotStatus().toPromise();
    console.log('this.snapshotStatus', this.snapshotStatus);
    if (this.snapshotStatus?.status == 'running') {
      this.timerId = setInterval(() => {
        this.usersServiceProxy.getSnapshotStatus().subscribe(data => {
          this.snapshotStatus = data;
          console.log('this.snapshotStatus', this.snapshotStatus);
          if (data?.status != 'running') {
            if (this.timerId) {
              clearInterval(this.timerId);
            }
          }
        });
      }, 5000);
    }
  }

  snapshotClick() {
    this.usersServiceProxy.getSnapshot().subscribe(
      data => {
        let blob = new Blob([data], {type: 'text/csv'});
        let downloadURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');

        link.href = downloadURL;
        link.download = "snapshot.csv";
        link.click();
      },
      error => {
        this.processServiceError(error);
      }
    );
  }

  async showSnapshotDlg() {
    const dialogRef = this._dialog.open(DlgSnapshotComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });

    const source = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.snapshotLoading = true;
        this.usersServiceProxy.createSnapshot(data).toPromise();

        setTimeout(() => {
          this.initSnapshotStatus();
          this.snapshotLoading = false;
        }, 5000);
      }
    });
  }

  searchClick() {
    this.loading = true;

    if (this.searchString != this.lastSearch) {
      this.page = 0;
    }

    this.lastSearch = this.searchString;

    this.usersServiceProxy.searchUsers(this.searchString, this.pageSize, this.page, this.sortDirection, this.sortByColumn, this.tierIndex === -1 ? null : this.tierIndex)
      .subscribe(result => {
          this.totalCount = result.totalItems;
          const addresses = this.searchString?.split(', ').map(address => address.trim());

          if (addresses && addresses.every(address => result.items.some(wallet => wallet.ethAddress === address))) {
            this.users = addresses.map(address => {
              return result.items.find(w => w.ethAddress === address);
            });
          }else {
            this.users = result.items;
          }
        },
        error => {
          this.processServiceError(error);
        },
        () => {
          this.loading = false;
        });
  }

  syncKYCClick(user: UserDTO) {
    this.usersServiceProxy.syncKYC(user.ethAddress)
      .subscribe(
        result => {
          this.waiting = false;

          if (result.applicantID) {
            this.showInfoModal(`Synced. Applicant id: ${result.applicantID}. Approved: ${result.kycConfirmed}`);
            user.kycConfirmed = result.kycConfirmed;
            user.sumAndSupApplicantId = result.applicantID;
          }
          else {
            this.showInfoModal(`Applicant not found`);
          }
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.sortByColumn = '';
      this.sortDirection = '';
    } else {
      this.sortByColumn = sort.active;
      this.sortDirection = sort.direction;
    }
    this.searchClick();
  }

  public navigateToUserDetails(userAddress: string) {
    this.router.navigate(['/user-details'], { queryParams: { userid: userAddress } });
  }

  pageChanged($event: any): void {
    this.page = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.searchClick();
  }

  async setBonusAmounts(): Promise<void> {
    let users = [];
    let amounts = [];
    let eventSource: any;

    try {
      let strings = this.bonusAmounts.split("\n").filter(x => x);

      for (let i = 0; i < strings.length; i++) {
        let row = strings[i].split(",");
        users.push(row[0]);
        amounts.push(new BigNumber(row[1]).shiftedBy(18).toFixed());
      }
    }
    catch {
      this.showErrorModal('Invalid data');
      return;
    }

    this.waiting = true;

    try {
      const partSize = 1000;
      const partsCount = Math.ceil(users.length / partSize);
      for (let i = 0; i < partsCount; i++) {
        let start = partSize * i;
        let end = start + partSize;
        end = end > users.length ? users.length : end;

        let usersPart = users.slice(start, end);
        let amountsPart = amounts.slice(start, end);
        //console.log("Processing users", usersPart, "amounts", amountsPart);
        eventSource = this.web3Service.setBonusAmounts(environment.bsc.bonusAddress, this.account, usersPart, amountsPart);
        await eventSource.receipt$.toPromise();
      }
      this.showInfoModal('Bonus amounts successfully set');
    } catch (err: any) {
      this.showErrorModal(err.reason || err.message || 'Error');
      console.info(err);
    }
    finally {
      this.waiting = false;
    }
  }
}
