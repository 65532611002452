<main class="ref-main p-40">
    <section class="ref-section">
      <!-- ref actions -->
      <div class="ref-actions py-4 px-3">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-5">
              <h2 class="subtitle-1 mb-0">Refferrers List</h2>
            </div>

            <div class="col-md-7 align-items-center d-flex">
              <mat-form-field appearance="fill" class="icon-form-field">
                <i class="icon icomoon-search text-muted"></i>
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="searchString">
              </mat-form-field>
              <button type="button" mat-raised-button color="primary">
                Search
              </button>
              <button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base">
                  <img (click)="saveRefData()" src="../../assets/svg/download-solid.svg"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END ref actions -->

      <div class="__deals-list">
          <!-- <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner> -->

          <div class="card not-found" *ngIf="!referrals || referrals?.length == 0">
              <div class="icon icomoon-box"></div>
              <div class="not-found__text mb-3">No referrals yet</div>
          </div>

          <!-- ref accordion -->
          <mat-accordion *ngIf="referrals && referrals?.length > 0" class="ref-accordian">
            <app-ref-extantion-panel *ngFor="let item of referrals | filter : 'address' : searchString" [ref]="item" [searchString]="searchString"></app-ref-extantion-panel>
          </mat-accordion>
      </div>
    </section>
</main>
